<template>
  <div class="navbar-start">
    <div class="navbar-menu">
      <div
        class="navbar-item"
        :class="{
          'is-hidden-tablet-only is-hidden-desktop-only': $experian
        }"
        v-if="hasAppHeaderSearch"
      >
        <AppHeaderVehicleSearch />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AppHeaderStart',
  components: {
    AppHeaderVehicleSearch: () =>
      import('modules/layout/AppHeaderVehicleSearch')
  },
  computed: {
    ...mapGetters('auth', ['isUser', 'isIntegration']),
    hasAppHeaderSearch() {
      const routes = ['companion', 'vehicle-profile', 'autocheck']
      return !this.isIntegration && routes.includes(this.$route.name)
    }
  }
}
</script>
